"use client";

import Link from "next/link";
import { ExternalLinkIcon } from "@radix-ui/react-icons";

import { licenses } from "@/config/pricing";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { CheckoutDownloadButton } from "@/components/checkout-download-button";
import TechStack from "@/components/tech-stack";

const features = [
  {
    id: "devtool-template",
    header: "Template",
    reverse: false,
    free: false,
    ...licenses.devtool,
  },
  {
    id: "mobile-template",
    header: "Template",
    reverse: false,
    free: false,
    ...licenses.mobile,
  },
  {
    id: "saas-template",
    header: "Template",
    reverse: false,
    free: false,
    ...licenses.saas,
  },
  {
    id: "startup-template",
    header: "Template",
    reverse: false,
    free: false,
    ...licenses.startup,
  },
  {
    id: "portfolio-template",
    header: "Template",
    reverse: false,
    free: true,
    ...licenses.portfolio,
  },
];

export default function FeatureSections() {
  return (
    <section id="features" className="container py-14">
      {features.map((feature) => {
        return (
          <div id={feature.id} key={feature.id}>
            <div className="mx-auto py-6 sm:py-20">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-16 gap-y-16 sm:gap-y-20 xl:mx-0 xl:max-w-none xl:grid-cols-5">
                <div
                  className={cn("m-auto xl:col-span-2", {
                    "xl:order-last": feature.reverse,
                  })}
                >
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">
                    {feature.header}
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
                    {feature.name}
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
                    {feature.description}
                  </p>
                  <div className="mt-8 flex flex-col sm:flex-row gap-4">
                    <CheckoutDownloadButton
                      free={feature.free}
                      priceId={feature.priceId}
                      repo={feature.repo}
                      owner={feature.owner}
                      showPrice
                      className={cn(
                        buttonVariants({ variant: "rainbow", size: "lg" }),
                        "w-full gap-2 whitespace-nowrap",
                      )}
                    >
                      {feature.cta}
                    </CheckoutDownloadButton>
                    <Link
                      className={cn(
                        buttonVariants({
                          variant: "outline",
                          size: "lg",
                        }),
                        "group relative gap-2 w-full whitespace-nowrap",
                      )}
                      href={feature.preview}
                      target="_blank"
                    >
                      Live Preview
                      <ExternalLinkIcon className="h-4 w-4" />
                    </Link>
                  </div>
                  <TechStack
                    className="mx-auto text-foreground pt-6"
                    technologies={[
                      "nextjs",
                      "react",
                      "typescript",
                      "tailwindcss",
                      "framermotion",
                      "shadcn",
                    ]}
                  />
                </div>
                <video
                  src={feature.video}
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="m-auto rounded-xl border shadow-2xl xl:col-span-3"
                />
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
}
